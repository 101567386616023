<template>

<com-content :blue="true" class="intro">

	<com-content :inner="true">

		<h1>Get started</h1>

		<p>We'd love to have you join us - sign up below and we will be in touch with your login credentials and to help you settle in.</p>

	</com-content>

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./../common/Content')

	}

}

</script>

<style scoped>

.intro {
	padding: 130px 0px 60px 0px;
}

.is-mobile .intro {
	padding: 80px 0px 50px 0px;
}

.intro:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 32px 0 32px;
	border-color: #1e3054 transparent transparent transparent;
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -32px;
}

.intro h1 {
	font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}

.is-mobile .intro h1 {
	font-size: 24px;
}

.intro p {
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	padding: 0px 10px;
	text-align: center;
	margin-top: 8px;
    color: #fff;
	margin-bottom: 40px;
}

.is-mobile .intro p {
	margin-bottom: 0px;
}

</style>